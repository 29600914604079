<script>
import Lottie from "@/components/widgets/lottie.vue";
import loadingAnimationData from "@/components/widgets/rhvddzym.json";
import successAnimation from "@/components/widgets/tqywkdcz.json";
import axios from "axios";

export default {
  name: "SendEmail",
  props: {
    company: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modalShow26: false,
      loadingAnimationData: {
        animationData: loadingAnimationData
      },
      successAnimation: {
        animationData: successAnimation
      },
      sending_an_email: false,
      status: 'initial', // initial, in_progress, success, error
    }
  },
  components: {lottie: Lottie},
  methods: {
    async sendEmail() {
      if (!this.user) {
        this.status = 'Unknown user'
        return
      }
      this.status = 'in_process'
      try {
        await axios.patch(`/user/send-welcome/${this.user.id}/`)
        this.status = 'success'
      } catch {
        this.status = 'error'
      }
    }
  }
}
</script>

<template>
  <b-button @click="modalShow26 = !modalShow26" variant="outline-secondary">
    <i class="mdi mdi-email me-1"></i>
    Invite User
  </b-button>

  <b-modal v-model="modalShow26" hide-footer class="v-modal-custom" body-class="p-0" centered
           hide-header-close>
    <div class="text-center px-5 pt-4">
      <div class="text-end">
        <button type="button" class="btn-close text-end" @click="modalShow26 = false"
                data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="mt-2">
        <div class="animation-container w-100 align-items-center" style="height: 100px">
          <Transition name="slide-up">
            <div class="animation-item mx-auto"
                 style="transform: translateX(10px)"
                 v-if="status === 'initial'">
              <i class="mdi mdi-email-send-outline display-1 text-success"></i>
            </div>
            <div class="animation-item mx-auto" style="transform: translateY(-25px) translateX(-50px);"
                 v-else-if="status === 'in_process'">
              <lottie trigger="hover" :options="loadingAnimationData" :height="150"
                      :width="200"/>
            </div>
            <div class="animation-item mx-auto" style="transform: translateY(-25px) translateX(-50px);"
                 v-else-if="status === 'success'">
              <lottie trigger="hover" :options="successAnimation" :height="150"
                      :width="200"/>
            </div>
            <div class="animation-item mx-auto"
                 style="transform: translateX(10px)"
                 v-else-if="status === 'error'">
              <i class="mdi mdi-email-remove-outline display-1 text-danger"></i>
            </div>
          </Transition>
        </div>

        <h4 class="mb-3 mt-4" :class="status === 'success' ? 'pt-4' : ''">
          <span v-if="status==='initial'">
            Invite <span class="text-success">{{ user.first_name + ' ' + user.last_name }}</span> to My InterRail
          </span>
          <span v-else-if="status==='in_process'">
            In Process...
          </span>
          <span v-else-if="status==='success'">
            Done!
          </span>
          <span v-else-if="status==='error'">
            Something went wrong!
          </span>
        </h4>
        <p class="text-muted fs-15 mb-4">
          <template v-if="status==='initial'">
            This is to remind you that after you click the button below,
            <span class="text-secondary">we will send a welcome email</span> to above mentioned user with his/her
            <span class="text-success">username</span> & <span class="text-success">password</span> to log in to
            <span><a href="https://my.interrail.uz">my.interrail.uz</a></span>.
          </template>
          <template v-else-if="status==='in_process'">
            <span class="d-block">
              We are inviting <span class="text-success">{{ user.first_name + ' ' + user.last_name }}</span> to My InterRail
            </span>
            <span class="text-secondary d-block">Please wait !</span>
          </template>
          <template v-else-if="status==='success'">
            We have successfully sent a welcome email to <span class="text-success">{{ user.first_name + ' ' + user.last_name }}.</span>
            Thank you !
          </template>
          <template v-else-if="status==='error'">
            Something went wrong while sending an email
            <span class="text-danger">Please try again later !</span>
          </template>
        </p>
        <div v-if="status === 'initial'" class="hstack gap-2 justify-content-center">
          <b-button @click="sendEmail()">Start sending an email</b-button>
          <b-button variant="light">
            Try later
          </b-button>
        </div>
        <div v-else-if="status === 'error'" class="hstack gap-2 justify-content-center">
          <b-button variant="light" @click="sendEmail()">Try again</b-button>
        </div>
      </div>
    </div>
    <div class="modal-footer bg-light p-3 mt-5 justify-content-center">
      <p class="mb-0 text-muted">
        InterRail Notifications Service
      </p>
    </div>
  </b-modal>

</template>

<style>
.animation-container {
  display: inline-block;
  position: relative;
  height: 1px;
}

.animation-container .animation-item {
  position: absolute;
  left: 40%;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateX(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>