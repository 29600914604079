<script>
import {defineComponent} from 'vue'
import UserInfo from "@/views/pages/accounts/users/tabs/Overview.vue"

export default defineComponent({
  name: "UpdateUser",
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  components: {
    UserInfo
  }
})
</script>

<template>
  <div id="UpdateCompanyUserModal" class="modal fade" tabindex="-1" aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-lg">
      <div class="modal-content border-0">
        <div class="modal-header border-bottom p-3">
          <h5 class="modal-title">
            Company User Modal
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body py-0 px-1" v-if="Object.keys(user).length > 0">
          <UserInfo :user="user" :show_info="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>