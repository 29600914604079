<template>

  <div class="tab-pane fade mb-3"
       :class="{'show active': $route.name === 'train_details_companies'}"
       id="train-companies" role="tabpanel">

    <template v-if="companies && companies.length > 0">
      <div class="row" v-for="company in companies" :key="'train_company_' + company.id">
        <div class="col-lg-3">
          <b-card no-body>
            <b-card-body class="table-responsive">
              <h5>Company Info</h5>
              <div class="table-responsive">
                <table class="table table-borderless mb-0">
                  <tbody>
                  <tr>
                    <th class="ps-0" scope="row">Name:</th>
                    <td class="text-muted">{{ company.name }}</td>
                  </tr>
                  <tr>
                    <th class="ps-0" scope="row">E-mail:</th>
                    <td class="text-muted">{{ company.email }}</td>
                  </tr>
                  <tr>
                    <th class="ps-0" scope="row">Phone:</th>
                    <td class="text-muted">{{ company.phone }}</td>
                  </tr>
                  <tr>
                    <th class="ps-0" scope="row">Address:</th>
                    <td class="text-muted">
                      <p>
                        {{ company.address }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th class="ps-0" scope="row">About:</th>
                    <td class="text-muted">
                      <p class="my-0">
                        {{ company.about ? company.about : '--' }}
                      </p>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </b-card-body>
          </b-card>
        </div>
        <div class="col-lg-9">
          <b-card no-body>
            <b-card-body class="table-responsive">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <h5>Company's Members</h5>
                <AddMember :company="company"/>
              </div>
              <div class="table-responsive">
                <table class="table table-bordered table-striped table-nowrap mb-0">
                  <thead>
                  <tr class="align-middle">
                    <th scope="col">Username</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Telegram Access</th>
                    <th scope="col">Email</th>
                    <th class="text-center" scope="col">Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-if="company.users.length > 0">
                    <tr v-for="user in company.users" :key="'train_company_user_' + user.id" class="align-middle">
                      <td>
                        <span class="link-primary">
                          <router-link
                              :to="{name: 'user_profile', params: {slug: user.username.toLowerCase()}}">
                            {{ user.username }}
                            <i class="ri-external-link-fill align-bottom ms-1"></i>
                          </router-link>
                        </span>
                      </td>
                      <td>{{ user.first_name }}</td>
                      <td>{{ user.last_name }}</td>
                      <td class="text-center">
                        <i v-if="user.telegram_access" class="ri-telegram-fill align-middle text-secondary fs-5"></i>
                        <i v-else class="ri-alert-fill text-danger align-middle fs-5"></i>
                      </td>
                      <td>{{ user.email }}</td>
                      <td class="text-center">
                        <div class="d-flex justify-content-evenly flex-wrap gap-2">
                          <InviteUser :company="company" :user="user"/>
                          <b-button
                              @click="editUser(company, user)"
                              data-bs-toggle="modal" data-bs-target="#UpdateCompanyUserModal"
                              variant="success">Edit User
                          </b-button>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="5">No Users</td>
                    </tr>
                  </template>
                  </tbody>
                </table>

              </div>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <lottie
                colors="primary:#405189,secondary:#08a88a"
                :options="no_company_widget"
                :height="150"
                :width="150"
            />
            <span class="fs-3">No Companies</span>
          </div>
        </div>
      </div>
    </template>
  </div>

  <UpdateCompanyUserModal :user="edit_user"/>
</template>

<script>
import axios from "axios";
import UpdateCompanyUserModal from "../modals/UpdateUser.vue";
import lottie from "@/components/widgets/lottie.vue";
import spxnqpau from "@/components/widgets/spxnqpau.json";

import AddMember from '@/views/pages/accounts/users/modal/CreateUser.vue'
import InviteUser from "@/views/pages/trains/details/components/InviteUser.vue";

export default {
  name: "containers_tab",
  components: {
    lottie,
    UpdateCompanyUserModal,
    AddMember,
    InviteUser
  },
  data() {
    return {
      companies: [],
      edit_user: {},
      no_company_widget: {animationData: spxnqpau},
    }
  },
  computed: {
    train_id() {
      try {
        return JSON.parse(localStorage.getItem('train'))['id']
      } catch {
        return null
      }
    }
  },
  methods: {
    async getTrainCompanies() {
      try {
        let response = await axios.get(`/customer/company/${this.train_id}/`)
        this.companies = response.data.results
      } catch {
        alert('Something went wrong')
      }
    },
    editUser(company, user) {
      this.edit_user = user
      this.edit_user.company = company
    }
  },
  mounted() {
    this.getTrainCompanies()
  }
}
</script>

<style scoped>

</style>